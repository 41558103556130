@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Noto Sans TC", sans-serif,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

/* antd message 變更 */
.ant-message {
  font-size:23px !important;
  color: #595959 !important;
}

/* 滾動條樣式 */
::-webkit-scrollbar-track-piece {
  background-color: #f8f8f8;
}
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  background-clip: padding-box;
  min-height: 28px;
  border-radius: 10px;

}
::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
  border-radius: 10px;
}

/* .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background: rgba(255, 255, 255, 0.01) !important;
} */

.my-icon-style {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -7px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 1;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.clickRowStyl {
  background-color: rgba(253, 255, 203, 0.5);
}

.WocWo-select-bg .ant-select-selector {
  border-radius: 10px;
}

.WocWo-select-bg .ant-select-selection-item {
  color: #ffffff !important;
}
.WocWo-select-bg .ant-select-arrow {
  color: #ffffff !important;
}
.ant-pagination-total-text {
  flex: 1;
  text-align: left;
}


.wocwo-productivity .ant-progress-text {
  display: none;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav .productivity {
  margin: 0px 0px 5px 0px;
}

/* antd tabs  */
.ant-tabs-tab{
  font-size: 24px !important; 
  padding:8px 16px !important;
}
.ant-table-pagination-right {
  margin: 8px 0 !important;
}
/* tab 原先設定是隨子容器大小決定 現在跟隨父元素 */
.ant-tabs-tabpane .ant-tabs-tabpane-active,
.ant-tabs-content,
.ant-tabs-tabpane > .ant-row
{
  height: 100% !important;
}
/* ----------------------- */

/* table min col min width */
.row-min-width {
  width: 100%;
  min-width: 90px;
  max-width: 280px;
}
.row-last-col-width{
  width: 100%;
  min-width: 90px;
}
.col-white-space{
  white-space: nowrap; /*//强制文本在一行内输出 */
  overflow: hidden; /*隐藏溢出部分*/
  text-overflow: ellipsis; /*//對溢出部分加上...*/
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.wos-tk-table-col{
  font-size: 16px;
  padding: 4px;
}


/* ------------------------- */

/* all input & table col key font-size */
input,
.ant-select-selection-item,
td,
th {
  font-size: 21px;
}
/* ---------------------------------- */
.pdf_center {
  width: max-content;
  max-width: 100%;
  height: max-content;
  max-height: 100%;
}
/* 控制新增多單的 -號位置 */
#multipleWonForm .dynamic-delete-button-box {
  position: relative;
  height: 100%;
  width: 100%;
}

.woc-productivity .ant-progress-text{
  display: none !important; 
}
.dynamic-delete-button {
  position: absolute;
  top: 30px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

