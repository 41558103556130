/* 時間軸亮 */
.prefix__b {
  /* fill: #55eb31 */
  fill: #55EB31
}

/* 時間軸燈亮 */
.prefix__h {
  fill: #267068
}

.prefix__dark {
  fill:#C4C4C4
}

.prefix__pass {
  fill:#699f5b
}

.prefix__c {
  fill: #267068
}

.prefix__c {
  font-size: 17px
}

.prefix__c,
.prefix__d,
.prefix__l {
  font-family: MicrosoftJhengHeiRegular, Microsoft JhengHei
}

.prefix__d,
.prefix__l,
.prefix__o {
  fill: #bebebe
}

.prefix__d {
  font-size: 16px
}

.prefix__e {
  fill: #c4c4c4
}

.prefix__g {
  fill: #fff
}

.prefix__r {
  fill: #707070
}

.prefix__l {
  font-size: 13px
}

.prefix__r {
  fill-rule: evenodd
}
